<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="grounding.png">
    <template v-slot:title> Practice grounding techniques</template>
    <template v-slot:subtitle>
      Grounding techniques help you get out of your head and into your body to
      pause your running thoughts.
    </template>
  </Banner>

  <!-- WAYS TO GROUND YOURSELF -->
  <section class="pt-10 md:pt-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Here are some ways to ground yourself.
      </div>

      <!-- Source: https://learnvue.co/2019/12/an-overview-of-vue-keep-alive/#what-is-keep-alive -->
      <!-- Tabs -->
      <div class="tabs-block">
        <div class="mb-0 is-centered">
          <div class="flex">
            <button
              class="w-6/12 px-4 py-5 text-lg font-bold text-center text-blue-600 rounded tab"
              v-for="tab in tabs"
              :key="tab"
              @click="component = tab"
              :class="{ active: component === tab }"
            >
              <span v-if="tab == 'OtherExercises'"
                >Other Grounding Exercises</span
              >
              <span v-else> 5-4-3-2-1 Exercise </span>
            </button>
          </div>
        </div>
        <div class="max-w-5xl mx-auto">
          <component :is="component" />
        </div>
      </div>
    </div>
  </section>

  <FooterCard
    img="try-another-coping.png"
    text="View Tools & Resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.<br />
      Discover more coping strategies to add to your toolkit.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner";
import FooterCard from "@/components/FooterCard";
import Exercise from "@/components/tabs/Exercise.vue";
import OtherExercises from "@/components/tabs/OtherExercises.vue";

export default {
  name: "PracticeGroundingTechniques",
  components: {
    Banner,
    FooterCard,
    OtherExercises,
    Exercise,
  },
  data() {
    return {
      tabs: ["Exercise", "OtherExercises"],
      component: "Exercise",
    };
  },
};
</script>

<style scoped>
.tabs-block {
  background: #f8f8f8;
  border: 5px solid #3c5eaa;
  border-radius: 10px;
}

.active {
  background: #3c5eaa;
  color: #fff;
}
</style>
