<template>
  <ul
    class="px-4 py-6 list-decimal list-outside bg-gray-200 list md:px-14 marker:text-green"
  >
    <li class="m-2">
      Think of all the words you can remember in another language.
    </li>
    <li class="m-2">Recite a poem you memorized as a child.</li>
    <li class="m-2">
      Describe in minutes detail something you do every day like brushing your
      teeth, picking up the toothbrush. I turn on the water. I wet the
      toothbrush. I put toothpaste on the toothbrush etc.
    </li>
    <li class="m-2">
      Imagine a time you felt very safe and describe it in great detail using
      all five senses.
    </li>
    <li class="m-2">
      When and stretch your fingers and toes. Relax them and repeat.
    </li>
    <li class="m-2">
      Repeat a prayer, affirmation or mantra. Use a rosary or mala beads to help
      another repetition.
    </li>
    <li class="m-2">Count backwards by three from 100.</li>
    <li class="m-2">
      List things you can do from the simplest to the most complicated.
    </li>
    <li class="m-2">
      Play old card games in your head like Geography, you say the name of a
      place and use the last letter of that place as the first letter of the
      next one.
    </li>
  </ul>
</template>

<script>
export default {
  name: "OtherExercises",
};
</script>

<style scoped>
::marker {
  font-weight: bold;
  color: #22b46e;
}
</style>
