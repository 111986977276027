<template>
  <div class="px-4 py-6 md:px-14">
    <p>
      Focus your attention lightly on an object in front of you. Say “I see“ and
      then name something in your peripheral vision. Then say “I see“ again and
      name something else in your peripheral vision. Continue until you have
      named five things.
    </p>
    <br />

    <p>
      Maintaining focus say “I hear” and name something you hear. Like before
      say “I hear“ and name something until you have named five things.
      Maintaining focus, say the words “I feel“ and name something you are
      touching. Again, until you have named five things. Next go through naming
      four things you see, hear and feel. Then three things, then two, the one.
    </p>
    <br />
    <p>
      If you’ve calmed down before completing the cycle from five to one it’s ok
      to stop. If one eye’s doesn’t quite do it. It’s ok to repeat the exercise.
      Allow about 15 minutes to complete one cycle. It usually works a bit
      better to find new things to name each time, but it’s fine to use the same
      one. If there’s not that much around you.
    </p>
    <br />

    <p>
      After you’ve got the hang of it, feel free to make up your own 5-4-3-2
      meditation for example, you could notice five colors, five shapes and five
      textures. On your five favorite food, five favorite places, five favorite
      movies.
    </p>
  </div>
</template>

<script>
export default {
  name: "Exercise",
};
</script>

<style scoped></style>
